import {
    addDays,
    eachDayOfInterval,
    getDate,
    getDay,
    getHours,
    getMinutes,
    getMonth,
    getYear,
    isAfter,
    isBefore,
    isEqual,
    isToday,
    parseISO,
} from 'date-fns';

import { DealershipInformationPage, orgTypes } from '../../lib/api/models/umbraco';
import { DateStyle, formatDate, generateHours } from './date.helper';
import { SpecialDay } from '../../lib/api/models/umbraco/organization.types';

export function getCalculatedDate(dayOfWeek: orgTypes.DayOfWeek, dates: Array<Date>): Date | undefined {
    switch (dayOfWeek) {
        case 'Monday':
            return dates.find((date) => getDay(date) === 1);
        case 'Tuesday':
            return dates.find((date) => getDay(date) === 2);
        case 'Wednesday':
            return dates.find((date) => getDay(date) === 3);
        case 'Thursday':
            return dates.find((date) => getDay(date) === 4);
        case 'Friday':
            return dates.find((date) => getDay(date) === 5);
        case 'Saturday':
            return dates.find((date) => getDay(date) === 6);
        case 'Sunday':
            return dates.find((date) => getDay(date) === 0);

        default:
            return undefined;
    }
}

export function setDateParts(targetDate: Date, baseDate: Date): Date {
    return new Date(getYear(baseDate), getMonth(baseDate), getDate(baseDate), getHours(targetDate), getMinutes(targetDate), 0);
}

export function compareDateOnly(a: Date, b: Date): boolean {
    const firstDate = new Date(getYear(a), getMonth(a), getDate(a));
    const secondDate = new Date(getYear(b), getMonth(b), getDate(b));
    return isEqual(firstDate, secondDate);
}

export function mapOpeningHours(
    departments: orgTypes.DealershipDepartment[],
    defaultSpecialDays?: Array<SpecialDay>
): Array<orgTypes.DealershipDepartment> {
    const todaysDate = new Date();
    const currentWeekDates = eachDayOfInterval({
        start: todaysDate,
        end: addDays(todaysDate, 6),
    });
    const mappedDepartmentList: orgTypes.DealershipDepartment[] = [];

    for (let index = 0; index < departments.length; index++) {
        const dep = departments[index];
        //Populate department special days with generic special days, only if department special days doesn't have a date on same day as generic special days
        const specialDays = defaultSpecialDays?.filter((x) => !dep.specialDays.some((s) => s.date === x.date)).concat(dep.specialDays ?? []);

        dep.openingHours.forEach((op) => {
            const fromDate = setDateParts(parseISO(op.from), todaysDate);
            const toDate = setDateParts(parseISO(op.to), todaysDate);
            op.from = formatDate(fromDate, DateStyle.yyyy_mm_dd_hh_ss);
            op.to = formatDate(toDate, DateStyle.yyyy_mm_dd_hh_ss);
            op.calculatedDate = getCalculatedDate(op.dayOfWeek, currentWeekDates) ?? new Date();

            // Special Day
            const matchingSpecialDay = specialDays?.find((specialDay) => compareDateOnly(parseISO(specialDay.date), op.calculatedDate));
            if (matchingSpecialDay) {
                op.from = formatDate(setDateParts(parseISO(matchingSpecialDay.from), todaysDate), DateStyle.yyyy_mm_dd_hh_ss);
                op.to = formatDate(setDateParts(parseISO(matchingSpecialDay.to), todaysDate), DateStyle.yyyy_mm_dd_hh_ss);
                op.closed = matchingSpecialDay.closed;
            }
        });

        dep.openingHours.sort((a, b) => {
            if (a.calculatedDate === undefined || b.calculatedDate === undefined) {
                return 0;
            }
            if (a.calculatedDate < b.calculatedDate) {
                return -1;
            }
            if (a.calculatedDate > b.calculatedDate) {
                return 1;
            }
            return 0;
        });
        mappedDepartmentList.push(dep);
    }

    return mappedDepartmentList;
}

export function isDealershipOpen(start: Date, end: Date): boolean {
    const now = new Date();
    return isAfter(now, start) && isAfter(end, now);
}

export function getOpeningText(openingHours: orgTypes.OpeningHour[]): string {
    for (let i = 0; i < openingHours.length; i++) {
        const todaysOpening = openingHours[i];

        if (isToday(todaysOpening.calculatedDate)) {
            if (todaysOpening.closed) {
                return 'Lukket';
            }

            if (isDealershipOpen(parseISO(todaysOpening.from), parseISO(todaysOpening.to))) {
                return `Åben til kl. ${formatDate(parseISO(todaysOpening.to), DateStyle.HH_mm)}`;
            }

            const now = new Date();
            if (isBefore(now, parseISO(todaysOpening?.from ?? ''))) {
                return `Lukket. Åbner kl. ${formatDate(parseISO(todaysOpening.from), DateStyle.HH_mm)}.`;
            }

            if (isAfter(now, parseISO(todaysOpening?.to ?? ''))) {
                let loopBackIndex = i + 1;
                let itemFound = false;
                let attempts = 0;

                while (!itemFound && attempts < openingHours.length) {
                    const calculatedIndex = loopBackIndex % openingHours.length;

                    if (calculatedIndex === i) {
                        loopBackIndex++;
                        attempts++;
                        continue;
                    }

                    const nextDay = openingHours[calculatedIndex];

                    if (!nextDay.closed) {
                        itemFound = true;
                        const text = Math.abs(i - loopBackIndex) > 1 ? nextDay.dayName?.toLocaleLowerCase() : 'i morgen';
                        return `Lukket. Åbner ${text} kl. ${formatDate(parseISO(nextDay.from), DateStyle.HH_mm)}`;
                    }
                    loopBackIndex++;
                    attempts++;
                }

                return 'Could not find an available opening hours';
            }
        }
    }

    return 'No opening hours provided.';
}

/**
 * Test Drive Opening Hours
 */
export function createTimeSlots(
    dealership: DealershipInformationPage | undefined,
    date: Date = new Date(),
    defaultSpecialDays?: SpecialDay[]
): Date[] {
    if (!dealership || dealership.testDriveOpeningHours?.length === 0) {
        return [];
    }
    const currentWeekDates = eachDayOfInterval({
        start: date,
        end: addDays(date, 6),
    });

    const salesDepartment = dealership.departments.find((x) => x.departmentType === 'Salg');

    const mappedHours = dealership.testDriveOpeningHours[0].openingHours.map((op) => {
        const opCalculatedDate = getCalculatedDate(op.dayOfWeek, currentWeekDates) ?? new Date();
        const specialDay =
            dealership.testDriveOpeningHours[0].specialDays.find((x) => compareDateOnly(opCalculatedDate, parseISO(x.date))) ??
            salesDepartment?.specialDays.find((x) => compareDateOnly(opCalculatedDate, parseISO(x.date))) ??
            defaultSpecialDays?.find((x) => compareDateOnly(op.calculatedDate, parseISO(x.date)));

        const openingHour = specialDay ?? op;
        const fromDate = setDateParts(parseISO(openingHour.from), date);
        const toDate = setDateParts(parseISO(openingHour.to), date);
        return {
            ...op,
            from: formatDate(fromDate, DateStyle.yyyy_mm_dd_hh_ss),
            to: formatDate(toDate, DateStyle.yyyy_mm_dd_hh_ss),
        };
    });

    const op = extractOpeningByDayOfWeek(date, mappedHours);

    if (!op || op.closed) {
        return [];
    }

    const from = new Date(op.from);
    const to = new Date(op.to);

    return generateHours(from, to, 30);
}

function extractOpeningByDayOfWeek(date: Date, openingHours: Array<orgTypes.OpeningHour>): orgTypes.OpeningHour | undefined {
    const dayOfTheWeek = getDay(date);

    switch (dayOfTheWeek) {
        case 1:
            return openingHours.find((x) => x.dayOfWeek === 'Monday');
        case 2:
            return openingHours.find((x) => x.dayOfWeek === 'Tuesday');
        case 3:
            return openingHours.find((x) => x.dayOfWeek === 'Wednesday');
        case 4:
            return openingHours.find((x) => x.dayOfWeek === 'Thursday');
        case 5:
            return openingHours.find((x) => x.dayOfWeek === 'Friday');
        case 6:
            return openingHours.find((x) => x.dayOfWeek === 'Saturday');
        case 0:
            return openingHours.find((x) => x.dayOfWeek === 'Sunday');
        default: {
            const x: never = dayOfTheWeek;
            return x;
        }
    }
}
